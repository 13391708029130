import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BookingDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;

  .booking-derails-label {
    color: #080133;
    font-size: 24px;
    text-align: center;
    font-family: airbnb-cereal-app;
    font-weight: normal;
  }
  .booking-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
    border-radius: 16px;
    padding-top: 10px;
    margin-top: 20px;
    padding-bottom: 25px;
  }

  .booking-details-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const BookingDetailsItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(50% - 10px);
  margin-left: 10px;

  .booking-details-item-label {
    color: ##808080;
    font-size: 18px;
    font-style: normal;
    font-family: airbnb-cereal-app;
    font-weight: normal;
    margin-top: 20px;
    word-wrap: break-word;
  }

  .booking-details-item-value {
    color: #808080;
    font-size: 16px;
    word-wrap: break-word;
    font-style: normal;
    font-family: airbnb-cereal-app;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 8px;
    width: 100%;
    margin-left: 5px;
  }
`;
