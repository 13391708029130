import { Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import React from "react";
import { DayChipWrapper } from "./style";

interface IProps {
  day: Dayjs;
  setSelectedDay: (day: string) => void;
  selectedDay: string;
}
export const DayChip: React.FC<IProps> = ({
  day,
  selectedDay,
  setSelectedDay,
}) => {
  const handleClick = () => {
    setSelectedDay(day.format("DD/MM/YYYY"));
  };

  const isSelected = selectedDay === day.format("DD/MM/YYYY");
  return (
    <DayChipWrapper onClick={handleClick} isSelected={isSelected}>
      <Typography>{day.format("ddd")}</Typography>
      <Typography>{day.format("D")}</Typography>
    </DayChipWrapper>
  );
};

export default DayChip;
