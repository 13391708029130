import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PackagePurchaseWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  margin-bottom: 30px;

  .title {
    color: #080133;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
  }

  .package-description {
    color: #808080;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
  }

  .customer-email {
    color: #080133;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 5px;
  }

  .package-code-label {
    color: #080133;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
  }

  .package-code-value {
    color: #5182ff;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
  }

  .save-reminder {
    color: #808080;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    margin-top: 15px;
  }
`;

export const PackageRedemptionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PackageCodeInputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .package-code-label {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #080133;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .package-code-input {
    border: 1px solid #d1d5db;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .package-code-button {
    font-family: airbnb-cereal-app;
  }

  .validation-error {
    color: #ff0000;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-size: 13px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
  }
`;

export const PackageDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  .package-details-label {
    color: #080133;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .package-details-items {
    width: 100%;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 40%);
    border-radius: 16px;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 10px;
  }
`;

interface IProps {
  valueColor: string;
}
export const PackageDetailsItemWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;

  .package-details-item-label {
    color: #080133;
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
  }

  .package-details-item-value {
    color: ${(props) => props.valueColor};
    font-family: airbnb-cereal-app;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
  }
`;
