import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ConfirmationHeader from "../../components/confirmation/ConfirmationHeader";
import ConfirmationBookingDetails from "../../components/shared/bookingDetails/ConfirmationBookingDetails";
import useTitle from "../../hooks/useTitle";

import { extractBookingDetails } from "../../Utils/extract";
import { ConfirmationWrapper } from "./style";

interface IProps {
  title: string;
}
const Confirmation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();

  const { companyName } = extractBookingDetails(searchParams);

  return (
    <ConfirmationWrapper>
      <ConfirmationHeader searchParams={searchParams} />
      <ConfirmationBookingDetails searchParams={searchParams} />
    </ConfirmationWrapper>
  );
};

export default Confirmation;
