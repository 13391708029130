import { Box } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Branch, Resource, Service } from "../../../hooks/useCompany";
import CustomizedSelect from "../../Select";
import { ServiceSelectorWrapper } from "./style";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

interface IProps {
  branchesMap: Map<string, Branch>;
  servicesMap: Map<string, Service>;
  resourcesMap: Map<string, Resource>;
  branchId: string | null;
  serviceId: string | null;
  resourceId: string | null;
}
const ServiceSelector: React.FC<IProps> = ({
  branchesMap,
  servicesMap,
  resourcesMap,
  branchId,
  serviceId,
  resourceId,
}) => {
  const [isDropdownChanged, setIsDropdownChanged] = useState<Boolean>(false);
  const {
    company,
    selectedLocation,
    selectedService,
    shouldShowServiceDropdown,
    isAllResourcesOptionEnabled,
    selectedResource,
    setSelectedLocation,
    setSelectedService,
    setSelectedResource,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const locationOptions2 = useMemo(() => {
    const options: any[] = [];
    if (!branchesMap || branchesMap.size === 0) return options;
    branchesMap.forEach((branch) => {
      options.push({
        value: branch.id,
        label: branch.name,
      });
    });

    let newSelectedLocation = options[0].value;

    if (branchId && !isDropdownChanged && branchesMap.has(branchId)) {
      newSelectedLocation = branchId;
    }

    if (serviceId && !isDropdownChanged && servicesMap.has(serviceId)) {
      const service = servicesMap.get(serviceId);
      newSelectedLocation = service?.branchId;
    }

    if (resourceId && !isDropdownChanged && resourcesMap.has(resourceId)) {
      const resource = resourcesMap.get(resourceId);
      const service = resource?.service;
      newSelectedLocation = service?.branchId;
    }

    setSelectedLocation(newSelectedLocation);

    return options;
  }, [branchId, serviceId, resourceId, branchesMap]);

  const servicesOptions2 = useMemo(() => {
    const options: any[] = [];
    if (!servicesMap || servicesMap.size === 0) return options;

    servicesMap.forEach((service) => {
      if (service.branchId === selectedLocation) {
        options.push({
          value: service.id,
          label: service.name,
        });
      }
    });

    let newSelectedService = options[0]?.value;

    if (serviceId && !isDropdownChanged && servicesMap.has(serviceId)) {
      const service = servicesMap.get(serviceId);
      newSelectedService = service?.id;
    }

    if (resourceId && !isDropdownChanged && resourcesMap.has(resourceId)) {
      const resource = resourcesMap.get(resourceId);
      const service = resource?.service;
      newSelectedService = service?.id;
    }

    setSelectedService(newSelectedService);
    return options;
  }, [serviceId, resourceId, servicesMap, selectedLocation]);

  const resourceOptions2 = useMemo(() => {
    const options: any[] = [];
    if (!resourcesMap || resourcesMap.size === 0) return options;

    resourcesMap.forEach((resource) => {
      if (
        (resource.service.id === selectedService &&
          shouldShowServiceDropdown) ||
        (resource.service.branchId === selectedLocation &&
          !shouldShowServiceDropdown)
      ) {
        options.push({
          value: resource.id,
          label: resource.name,
        });
      }
    });

    let newSelectedResource = options[0]?.value;

    if (resourceId && !isDropdownChanged && resourcesMap.has(resourceId)) {
      newSelectedResource = resourceId;
    }

    setSelectedResource(newSelectedResource);

    return isAllResourcesOptionEnabled
      ? [{ label: "All Resources", value: "" }]
      : options;
  }, [
    resourceId,
    resourcesMap,
    selectedLocation,
    selectedService,
    shouldShowServiceDropdown,
  ]);

  const onLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocation(event.target.value);
    setIsDropdownChanged(true);
  };

  const onServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedService(event.target.value);
    setIsDropdownChanged(true);
  };

  const onResourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedResource(event.target.value);
    setIsDropdownChanged(true);
  };

  return (
    <ServiceSelectorWrapper>
      <Box className="dropdown-menus">
        <CustomizedSelect
          value={selectedLocation}
          selectLabel="Location"
          options={locationOptions2}
          handleChange={onLocationChange}
        />
        {shouldShowServiceDropdown && (
          <CustomizedSelect
            value={selectedService}
            selectLabel="Service"
            options={servicesOptions2}
            handleChange={onServiceChange}
            className="select-menu-left-separator"
          />
        )}
        <CustomizedSelect
          value={selectedResource}
          selectLabel={company?.resourceDropdownLabel || "Yacht"}
          options={resourceOptions2}
          handleChange={onResourceChange}
          className="select-menu-left-separator"
        />
      </Box>
    </ServiceSelectorWrapper>
  );
};

export default ServiceSelector;
