import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useMemo } from "react";
import { DaySelectorHeaderWrapper } from "./style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomizedDatePicker from "../../DatePicker";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

interface IProps {
  selectedPickerDate: Date;
  setSelectedPickerDate: (date: Date) => void;
  setSelectedDay: (day: string) => void;
}

const DaySelectorHeader: React.FC<IProps> = ({
  selectedPickerDate,
  setSelectedPickerDate,
  setSelectedDay,
}) => {
  const {
    selectedService,
    servicesMap,
    shouldShowServiceDropdown,
    resourcesMap,
    selectedResource,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const selectedServiceId = shouldShowServiceDropdown
    ? selectedService
    : resourcesMap?.get(selectedResource)?.service?.id;

  const service = servicesMap?.get(selectedServiceId!);

  const handlePrevWeek = () => {
    const updatedSelectedPickerDate = dayjs(selectedPickerDate).subtract(
      7,
      "day"
    );
    setSelectedPickerDate(updatedSelectedPickerDate.toDate());
    setSelectedDay(updatedSelectedPickerDate.format("DD/MM/YYYY"));
  };

  const handleNextWeek = () => {
    const updatedSelectedPickerDate = dayjs(selectedPickerDate).add(7, "day");
    setSelectedPickerDate(updatedSelectedPickerDate.toDate());
    setSelectedDay(updatedSelectedPickerDate.format("DD/MM/YYYY"));
  };

  const isBackDisabled = useMemo(() => {
    return dayjs(selectedPickerDate)
      .subtract(7, "day")
      .startOf("day")
      .isBefore(dayjs().startOf("day"));
  }, [selectedPickerDate]);

  const isNextDisabled = useMemo(() => {
    if (!service?.advancedBookingDays) return false;
    return dayjs(selectedPickerDate)
      .add(7, "day")
      .startOf("day")
      .isAfter(dayjs().add(service?.advancedBookingDays!, "day"));
  }, [selectedPickerDate, service?.advancedBookingDays]);

  return (
    <DaySelectorHeaderWrapper>
      <Box className="arrow-icon">
        <Button onClick={handlePrevWeek} disabled={isBackDisabled}>
          <ArrowBackIcon />
        </Button>
      </Box>
      <Box className="day-selector-calendar">
        <Typography className="day-selector-calendar-label">
          Choose a Date
        </Typography>
        <CustomizedDatePicker
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
        />
      </Box>
      <Box className="arrow-icon">
        <Button onClick={handleNextWeek} disabled={isNextDisabled}>
          <ArrowForwardIcon />
        </Button>
      </Box>
    </DaySelectorHeaderWrapper>
  );
};

export default DaySelectorHeader;
