import { Typography } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import Select from "react-select";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { DEFAULT_MAX_QUANTITY } from "../../../Utils/constants";

import { QuantitySelectorWrapper } from "./style";

const QuantitySelector: React.FC = () => {
  const {
    quantity,
    setQuantity,
    selectedService,
    servicesMap,
    shouldShowServiceDropdown,
    selectedResource,
    resourcesMap,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const selectedServiceId = shouldShowServiceDropdown
    ? selectedService
    : resourcesMap?.get(selectedResource)?.service?.id;

  const service = servicesMap?.get(selectedServiceId!);

  const options = useMemo(() => {
    const options = [];
    const maxQuantity = service?.maxQuantity || DEFAULT_MAX_QUANTITY;
    for (let index = 1; index <= maxQuantity; index++) {
      options.push({ value: index, label: index });
    }
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service?.maxQuantity, selectedService]);

  const onSelect = (option: any) => {
    setQuantity(option.value);
  };

  return (
    <QuantitySelectorWrapper>
      <Typography className="label">Select a Quantity</Typography>
      <Select
        id="quantity-selector"
        value={{ value: quantity, label: quantity }}
        options={options}
        onChange={onSelect}
        className="select"
      />
    </QuantitySelectorWrapper>
  );
};

export default QuantitySelector;
