import styled from "@emotion/styled";
import { down } from "styled-breakpoints";
export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;

  ${down("sm")} {
    width: 95%;
  }
`;
