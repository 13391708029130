import React, { useContext } from "react";
import dayjs from "dayjs";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import {
  convertMinutesToHours,
  formatDurationTime,
  getFormattedTime,
} from "../../Utils/format";
import BookingDetails from "../shared/bookingDetails";
import { getPrice } from "../../Utils/extract";

const CheckoutBookingDetails: React.FC = () => {
  const {
    company,
    selectedSlot,
    selectedDuration,
    selectedLocation,
    selectedResource,
    selectedService,
    servicesMap,
    resourcesMap,
    branchesMap,
    quantity,
    shouldShowServiceDropdown,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const companyName = company?.name;
  const locationName = branchesMap?.get(selectedLocation)?.name;
  const serviceName = shouldShowServiceDropdown
    ? servicesMap?.get(selectedService)?.name
    : resourcesMap?.get(selectedResource)?.service.name;
  const date = dayjs(selectedSlot?.fullDate).format("dddd, MMMM DD");
  const startTime = getFormattedTime(
    selectedSlot?.fullDate!,
    "h:mm A",
    false,
    "Asia/Dubai"
  );
  const duration = formatDurationTime(
    selectedDuration?.durationTime!,
    company!,
    selectedResource
  );
  const price =
    (selectedSlot!.price * quantity).toString() + ` ${company?.currency || "AED"}`;

  const selectedServiceId = shouldShowServiceDropdown
    ? selectedService
    : resourcesMap?.get(selectedResource)?.service?.id;

  const service = servicesMap?.get(selectedServiceId!);

  const priceRow =
    service?.maxQuantity && service.maxQuantity > 1
      ? [
          { label: "Price", value: `${getPrice(price)} AED` },
          { label: "quantity", value: `${quantity}` },
        ]
      : [{ label: "Price", value: `${getPrice(price)} AED` }];

  return (
    <BookingDetails
      details={[
        {
          items: [
            { label: "Company", value: companyName! },
            { label: "Location", value: locationName! },
          ],
        },
        {
          items: [
            { label: "Date", value: date },
            { label: "Service", value: serviceName! },
          ],
        },
        {
          items: [
            { label: "Start Time", value: startTime },
            { label: "Duration", value: duration },
          ],
        },
        {
          items: priceRow,
        },
      ]}
    />
  );
};
export default CheckoutBookingDetails;
