import { Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
  BookingSlot,
} from "../../../hooks/useAvailableStartingTimes";
import { isAfterCutOff } from "../../../Utils/format";
import SlotsSection from "./SlotsSection";
import { AvailableStartingTimesWrapper } from "./style";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  bookingSlots: AvailableStartingTimesSlot[];
}
const AvailableStartingTimes: React.FC<IProps> = ({
  availableStartingTimes,
  bookingSlots,
}) => {
  const {
    company,
    quantity,
    selectedService,
    servicesMap,
    selectedResource,
    shouldShowServiceDropdown,
    resourcesMap,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const selectedServiceId = shouldShowServiceDropdown
    ? selectedService
    : resourcesMap?.get(selectedResource)?.service?.id;

  const service = servicesMap?.get(selectedServiceId!);

  const morningStartingTimes = useMemo(() => {
    if (!bookingSlots) return [];
    return bookingSlots.filter((slot) => {
      const isMorningSlot = slot.available >= quantity && slot.time < "1200";
      
      return (
        isMorningSlot &&
        isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone)
      );
    });
  }, [
    bookingSlots,
    quantity,
    service?.cutoff,
    company?.timezone,
  ]);

  const afternoonStartingTimes = useMemo(() => {
    if (!bookingSlots) return [];
    return bookingSlots.filter((slot) => {
      const isAfternoonSlot = slot.available >= quantity && slot.time >= "1200";
      
      return (
        isAfternoonSlot &&
        isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone)
      );
    });
  }, [
    bookingSlots,
    quantity,
    service?.cutoff,
    company?.timezone,
  ]);

  return (
    <AvailableStartingTimesWrapper>
      <Typography className="availability-Label">
        Available Starting Times
      </Typography>
      {morningStartingTimes.length > 0 || afternoonStartingTimes.length > 0 ? (
        <>
          <SlotsSection
            label="Morning"
            slots={morningStartingTimes}
            availableStartingTimes={availableStartingTimes}
          />
          <SlotsSection
            label="Afternoon"
            slots={afternoonStartingTimes}
            availableStartingTimes={availableStartingTimes}
          />
        </>
      ) : (
        <Typography className="no-slots-label">
          Ughh, Seems like we are fully booked today. Join on us on another day?
        </Typography>
      )}
    </AvailableStartingTimesWrapper>
  );
};
export default AvailableStartingTimes;
