import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HeaderWrapper } from "./style";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

interface IProps {
  headerLabel: string;
  showBackButton?: boolean;
}

const Header: React.FC<IProps> = ({ headerLabel, showBackButton }) => {
  const naviagte = useNavigate();
  const goBack = () => {
    naviagte(-1);
  };
  return (
    <HeaderWrapper>
      {showBackButton && (
        <Box className="back-arrow">
          <ArrowBackIosNewIcon onClick={goBack} className="back-arrow-icon" />
        </Box>
      )}
      <Box className="header">
        <Typography className="header-label">{headerLabel}</Typography>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
