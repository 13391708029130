import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import GeneralStatus from "../../components/status/GeneralStatus";
import SuccessfulStatus from "../../components/status/SuccessfulStatus";
import UnSuccessfulPayment from "../../components/status/UnSuccessfulPayment";
import useTitle from "../../hooks/useTitle";

import { RequestStatus, STATUS_MESSAGES } from "../../Utils/constants";
import { StatusWrapper } from "./style";

interface IProps {
  title: string;
  location?: { state: any };
}

const Status: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const status = searchParams.get("requestStatus");

  return (
    <StatusWrapper>
      {status === RequestStatus.FailedCancellation && (
        <GeneralStatus
          header="BOOKING CANCELLATION FAILED"
          title={STATUS_MESSAGES.get(RequestStatus.FailedCancellation)!}
        ></GeneralStatus>
      )}

      {status === RequestStatus.FailedPackageCancellation && (
        <GeneralStatus
          header="FAILED PACKAGE CANCELLATION "
          title={STATUS_MESSAGES.get(RequestStatus.FailedPackageCancellation)!}
        ></GeneralStatus>
      )}

      {status === RequestStatus.SuccessfulPackageCancellation && (
        <GeneralStatus
          header="SUCCESSFUL PACKAGE CANCELLATION"
          title={STATUS_MESSAGES.get(RequestStatus.SuccessfulCancellation)!}
        ></GeneralStatus>
      )}

      {status === RequestStatus.SuccessfulCancellation && (
        <SuccessfulStatus locationState={location?.state} />
      )}

      {status === RequestStatus.UnSuccessfulPayment && <UnSuccessfulPayment />}
    </StatusWrapper>
  );
};

export default Status;
