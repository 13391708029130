import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CheckoutContextProvider from "./context/CheckoutContext";
import CssBaseline from "@mui/material/CssBaseline";

import "./fonts/AirbnbCerealMedium.ttf";
import "./fonts/AirbnbCerealLight.ttf";
import "./fonts/AirbnbCerealBold.ttf";
import "./fonts/AirbnbCerealBlack.ttf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <CheckoutContextProvider children={<App />} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
