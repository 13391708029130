import { Typography } from "@mui/material";
import React from "react";
import { ConfirmationHeaderWrapper } from "./style";

// import { ReactComponent as ThankIcon } from "../../assets/ThankIcon.svg";

interface IProps {
  searchParams: URLSearchParams;
}
const ConfirmationHeader: React.FC<IProps> = ({ searchParams }) => {
  const customerEmail = searchParams.get("customerEmail");
  return (
    <ConfirmationHeaderWrapper>
      <Typography className="header">Booking Confirmed</Typography>
      {/* <Box className="icon">
        <ThankIcon />
      </Box> */}
      <Typography className="email-label">
        Payment received and your booking is confirmed. We've sent all the
        details to your email at :
      </Typography>
      <Typography className="email-info">{customerEmail}</Typography>
    </ConfirmationHeaderWrapper>
  );
};

export default ConfirmationHeader;
