import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CancellationButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cancel-button {
    font-size: 20px;
    font-family: airbnb-cereal-app;
    font-weight: 500;
    color: #ffffff;
    background: #ff0000;
    height: 62px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 6px 40px #d4d9e8;
    text-transform: capitalize;
  }
`;
