import React from "react";
import { UnSuccessfulPaymentWrapper } from "./style";
import { ReactComponent as Group } from "../../assets/Group.svg";
import { Typography } from "@mui/material";
import { RequestStatus, STATUS_MESSAGES } from "../../Utils/constants";
const UnSuccessfulPayment: React.FC = () => {
  return (
    <UnSuccessfulPaymentWrapper>
      <Group />
      <Typography className="header">Oops...Payment not processed </Typography>
      <Typography className="content">
        {STATUS_MESSAGES.get(RequestStatus.UnSuccessfulPayment)!}
      </Typography>
    </UnSuccessfulPaymentWrapper>
  );
};

export default UnSuccessfulPayment;
