import React, { useContext, useEffect, useState } from "react";
import Footer from "../../components/shared/footer";
import CheckoutBookingDetails from "../../components/Checkout/CheckoutBookingDetails";
import RiderInformation from "../../components/Checkout/RiderInformation";
import Header from "../../components/shared/header";
import useConfirmBooking from "../../hooks/useConfirmBooking";
import { CheckoutWrapper } from "./style";
import PackageRedemption from "../../components/package/PackageRedemption";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import useTitle from "../../hooks/useTitle";

interface IProps {
  title: string;
}
const Checkout: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [packageCode, setPackageCode] = useState<string>("");
  const [isPackageCodeValid, setIsPackageCodeValid] = useState<boolean>(false);

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const { confirmBooking, isLoading, bookingError, bookingResponse } =
    useConfirmBooking();

  return (
    <>
      <CheckoutWrapper>
        <Header headerLabel="Confirm Booking" showBackButton={true} />
        <CheckoutBookingDetails />
        <PackageRedemption
          packageCode={packageCode}
          setPackageCode={setPackageCode}
          isPackageCodeValid={isPackageCodeValid}
          setIsPackageCodeValid={setIsPackageCodeValid}
        />
        <RiderInformation
          confirmBooking={confirmBooking}
          bookingError={bookingError}
          bookingResponse={bookingResponse}
          isLoading={isLoading}
          isPackageCodeValid={isPackageCodeValid}
          packageCode={packageCode}
        />
      </CheckoutWrapper>
      <Footer />
    </>
  );
};

export default Checkout;
