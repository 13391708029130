import React from "react";
import StatusHeader from "./StatusHeader";
import { StatusWrapper } from "./style";

interface IProps {
  header: string;
  title: string;
}
const Status: React.FC<IProps> = ({ header, title }) => {
  return (
    <StatusWrapper>
      <StatusHeader header={header} title={title} />
    </StatusWrapper>
  );
};

export default Status;
