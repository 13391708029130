import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ContextLoader from "../../../components/ContentLoader";
import BookingDetails from "../../../components/shared/bookingDetails";
import Footer from "../../../components/shared/footer";
import Header from "../../../components/shared/header";
import UserInformationForm, {
  IUserForm,
} from "../../../components/shared/userInformationForm";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import useCompany from "../../../hooks/useCompany";
import usePackagePurchase from "../../../hooks/usePackagePurchase";
import { formatPhoneNumber } from "../../../Utils/format";
import { PackagePurchaseWrapper } from "./style";

const PackagePurchase = () => {
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");
  const price = searchParams.get("price");
  const numOfSessions = searchParams.get("numOfSessions");

  const { setCompany } = useContext(CheckoutContext) as CheckoutContextProps;
  const { loading: isLoadingCompany, company } = useCompany(companyId!);
  const {
    isLoading: isLoadingPackagePurchase,
    purchasePackage,
    purchaseError,
  } = usePackagePurchase();

  useEffect(() => {
    if (company) {
      setCompany(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  const handleClick = async (formData: IUserForm) => {
    await purchasePackage({
      companyId: company!.id,
      price: Number(price) || 0,
      customerInformation: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formatPhoneNumber(formData.phone, formData.country),
      },
    });
  };

  return (
    <ContextLoader isLoading={isLoadingCompany}>
      <>
        <PackagePurchaseWrapper>
          <Header headerLabel="Confirm Booking" showBackButton={true} />
          <BookingDetails
            details={[
              {
                items: [
                  {
                    label: "Company",
                    value: company?.name,
                  },
                  {
                    label: "Price",
                    value: `${price} AED`,
                  },
                ],
              },
              {
                items: [
                  {
                    label: "Package Credits",
                    value: numOfSessions!,
                  },
                ],
              },
            ]}
          />
          <UserInformationForm
            error={purchaseError}
            isLoading={isLoadingPackagePurchase}
            userType={"Customer"}
            buttonLabel={"Processed to Payment"}
            onClick={handleClick}
          />
          {/* <PackageRedemption /> */}
        </PackagePurchaseWrapper>
        <Footer />
      </>
    </ContextLoader>
  );
};

export default PackagePurchase;
