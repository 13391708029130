import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { down } from "styled-breakpoints";

export const DaySelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 30px;

  .day-selector-label {
    color: #33334f;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-family: airbnb-cereal-app;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;

export const DaysListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
  ${down("sm")} {
    overflow: auto;
    justify-content: flex-start;
    width: 100%;
  }
`;

interface DayChipWrapperProps {
  isSelected: boolean;
}
export const DayChipWrapper = styled(Box)<DayChipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  font-size: 14px;
  text-align: center;
  font-family: airbnb-cereal-app-light;
  font-weight: normal;
  line-height: 140%;
  background-color: ${({ isSelected }) => (isSelected ? "#5182FF" : "#ffffff")};
  color: ${({ isSelected }) => (isSelected ? "#ffffff" : "#000000")};
  padding: 8px 18px;
  box-shadow: 0px 10px 40px #d4d9e8;
  border-radius: 12px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: 15px;
`;

export const DaySelectorHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${down("md")} {
    justify-content: center;
  }

  .arrow-icon {
    font-size: 35px;
    ${down("md")} {
      display: none;
    }
  }

  .day-selector-calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .day-selector-calendar-label {
      color: #33334f;

      margin: 0px 10px;
      font-size: 20px;
      font-style: normal;
      font-family: airbnb-cereal-app;
      font-weight: 500;
    }
  }
`;
