import { LoadingButton } from "@mui/lab";
import React from "react";
import { CancellationButtonWrapper } from "./style";

interface IProps {
  onClick: () => void;
  isLoading: boolean;
  buttonLabel: string;
}

const CancelButton: React.FC<IProps> = ({
  onClick,
  isLoading,
  buttonLabel,
}) => {
  return (
    <CancellationButtonWrapper>
      <LoadingButton
        variant="contained"
        onClick={onClick}
        loading={isLoading}
        disabled={isLoading}
        className="cancel-button"
      >
        {buttonLabel}
      </LoadingButton>
    </CancellationButtonWrapper>
  );
};

export default CancelButton;
