import Axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../Utils/constants";
export interface Company {
  id: string;
  name: string;
  logo: string;
  cancellation_policy: number;
  currency: string;
  companyAddress: string;
  companyEmail: string;
  companyPhoneNumber: string;
  mapLink: string;
  paymentsActive: boolean;
  timeUnit: "session" | "hour";
  resourceDropdownLabel?: string;
  resourcesInformation?: ResourceInformation[];
  branch: Branch[];
  footerPagesLinks: FooterPagesLinks;
  timezone: string;
  serviceDurationMultiples: number;
}

export interface FooterPagesLinks {
  aboutUs: string;
  terms: string;
  privacy: string;
  refund: string;
}

export interface ResourceInformation {
  resourceId: string;
  timeUnit: "session" | "hour";
  disclaimer: string;
}

export interface Branch {
  id: string;
  name: string;
  address: string;
  companyId: string;
  startTime: string;
  endTime: string;
  bufferTime: number;
  services: Service[];
}

export interface Service {
  id: string;
  name: string;
  branchId: string;
  companyId: string;
  description: string;
  maxQuantity?: number;
  cutoff?: number;
  resource: string[];
  resourceNames: string[];
  advancedBookingDays?: number;
}
export interface Resource {
  id: string;
  name: string;
  service: Service;
}

const useCompany = (companyId: string) => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [branchesMap, setBranchesMap] = useState<Map<string, Branch>>(
    new Map()
  );
  const [servicesMap, setServicesMap] = useState<Map<string, Service>>(
    new Map()
  );
  const [resourcesMap, setResourcesMap] = useState<Map<string, Resource>>(
    new Map()
  );
  useEffect(() => {
    const fetchAllCompanies = async () => {
      setLoading(true);
      try {
        const { data: response } = await Axios.post(`${BASE_URL}/getCompanies`);
        const selectedCompany: Company = response.data.find(
          (_company: Company) => _company.id === companyId
        );

        const updatedBranchesMap = new Map<string, Branch>();
        selectedCompany.branch.forEach((branch) => {
          updatedBranchesMap.set(branch.id, branch);
        });
        const updatedServicesMap = new Map<string, Service>();
        const updatedResourcesMap = new Map<string, Resource>();
        selectedCompany.branch.forEach((branch) => {
          branch.services.forEach((service) => {
            updatedServicesMap.set(service.id, service);
            service.resource.forEach((resource, index) => {
              updatedResourcesMap.set(resource, {
                id: resource,
                name: service?.resourceNames
                  ? service?.resourceNames[index]
                  : service.name,
                service,
              });
            });
          });
        });
        setResourcesMap(updatedResourcesMap);
        setServicesMap(updatedServicesMap);
        setBranchesMap(updatedBranchesMap);
        setCompany(selectedCompany);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    fetchAllCompanies();
  }, [companyId]);

  return { company, loading, branchesMap, servicesMap, resourcesMap };
};

export default useCompany;
