import React from "react";
import { useNavigate } from "react-router-dom";
import useCancelBooking from "../../hooks/useCancelBooking";
import { RequestStatus } from "../../Utils/constants";
import { extractBookingDetails } from "../../Utils/extract";
import CancelButton from "../shared/cancelButton";

interface IProps {
  searchParams: URLSearchParams;
}

const CancelBookingButton: React.FC<IProps> = ({ searchParams }) => {
  const navigate = useNavigate();
  const bookingId = searchParams.get("bookingId");
  const { isLoading, cancelBooking } = useCancelBooking();

  const {
    companyName,
    locationName,
    serviceName,
    date,
    startTime,
    duration,
    price,
    quantity,
  } = extractBookingDetails(searchParams);

  const onSuccessfulCancellation = () => {
    navigate(`/status?requestStatus=${RequestStatus.SuccessfulCancellation}`, {
      state: {
        companyName,
        locationName,
        serviceName,
        date,
        startTime,
        duration,
        price,
        bookingId,
        quantity,
      },
    });
  };
  const onFailedCancellation = () => {
    navigate(`/status?requestStatus=${RequestStatus.FailedCancellation}`);
  };

  const handleClick = async () => {
    await cancelBooking({
      bookingId: bookingId!,
      onSuccessfulCancellation,
      onFailedCancellation,
    });
  };

  return (
    <CancelButton
      buttonLabel="CANCEL BOOKING"
      onClick={handleClick}
      isLoading={isLoading}
    />
  );
};

export default CancelBookingButton;
