import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PackagePurchaseDetails from "../../../components/package/PackagePurchaseDetails";
import useTitle from "../../../hooks/useTitle";

import { extractPackageDetails } from "../../../Utils/extract";
import { PackageConfirmationWrapper } from "./style";

interface IProps {
  title: string;
}
const PackageConfirmation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    document.title = title || "";
  }, [title]);

  const { numOfSessions, customerEmail, packageCode } =
    extractPackageDetails(searchParams);

  return (
    <>
      <PackageConfirmationWrapper>
        <PackagePurchaseDetails
          numOfSessions={numOfSessions!}
          customerEmail={customerEmail!}
          packageCode={packageCode!}
        />
      </PackageConfirmationWrapper>
    </>
  );
};

export default PackageConfirmation;
