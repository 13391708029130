import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { CountryCode } from "libphonenumber-js/min";
import { getCountryCallingCode } from "react-phone-number-input";
import { Company } from "../hooks/useCompany";
import { SESSION_DURATION, TimeUnits } from "./constants";
import { getTimeUnit } from "./extract";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getDateInTimezone = (
  date: string,
  givenFormat?: boolean,
  timezone?: string
) => {
  if (givenFormat) return dayjs(date, "MMMM Do YYYY").tz(timezone);
  return dayjs(date).tz(timezone);
};

export const getFormattedTime = (
  date: string,
  format: string,
  givenFormat?: boolean,
  timezone?: string
) => {
  const dateTime = getDateInTimezone(date, givenFormat, timezone);
  return dateTime.format(format);
};

export const convertMinutesToHours = (minutes: number) => {
  return `${minutes / 60} Hour${minutes > 60 ? "s" : ""}`;
};

export const convertMinutesToSessions = (minutes: number) => {
  return `${minutes / SESSION_DURATION} Session${minutes > 15 ? "s" : ""}`;
};

export const formatDurationTime = (
  durationInMinutes: number,
  company: Company,
  selectedResource: string
) => {
  const timeUnit = getTimeUnit(company, selectedResource);
  if (timeUnit === TimeUnits.HOUR)
    return convertMinutesToHours(durationInMinutes);
  if (timeUnit === TimeUnits.SESSION)
    return convertMinutesToSessions(durationInMinutes);
};

export const formatPhoneNumber = (phoneNumber: string, country: string) => {
  return `+${getCountryCallingCode(country as CountryCode)}${phoneNumber}`;
};

export const formatPackageCode = (packageCode: string) => {
  let formattedPackageCode = "";
  for (let i = 0; i < packageCode.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedPackageCode += "-";
    }
    formattedPackageCode += packageCode[i];
  }
  return formattedPackageCode;
};

export const diffInMinutes = (startTime: string, endTime: string) => {
  const start = dayjs(startTime, "HHmm");
  const end = dayjs(endTime, "HHmm");
  return end.diff(start, "minutes");
};

export const formatConfirmationSearchParams = (bookingResponse: any) => {
  return (
    `confirmation?companyName=${bookingResponse.company.companyName}` +
    `&location=${bookingResponse.branch.branchName}` +
    `&date=${dayjs(bookingResponse.date,"YYYYMMDD").format("MMMM Do YYYY")}` +
    `&startTime=${bookingResponse.startTime}` +
    `&serviceName=${bookingResponse.service.serviceName}` +
    `&durationInMinutes=${diffInMinutes(
      bookingResponse.startTime,
      bookingResponse.endTime
    )}` +
    `&price=${bookingResponse.service.servicePrice}` +
    `&customerEmail=${bookingResponse.customer.email}` +
    `&bookingId=${bookingResponse.id}` +
    `&timezone=${bookingResponse.timezone}` +
    `&orderStatusName=Paid`
  );
};

export const isAfterCutOff = (
  cutoff: number | undefined,
  slotFullDate: string,
  timezone: string | undefined
) => {
  if (!cutoff) return true;
  const cutOffTime = dayjs()
    .tz(timezone || "Asia/Dubai")
    .add(cutoff, "hour");

  const slotTime = dayjs(slotFullDate);
  console.log("cutoffTime", cutOffTime);
  console.log("slotTime", slotTime);
  return slotTime.isAfter(cutOffTime);
};
